<template>
  <transition name="fade">
        <div class="games page" v-touch:longtap="() => { this.tmode = !this.tmode } " >
        <a :href="`/game/${feature.id}`" v-if="feature" >
            <div class="feature" v-ripple>
                <div class="cover">
                    <img :src="feature.coverArt" >
                </div>

                <div class="content">
                    <h1>Games</h1>
                    <h2> Play {{feature.title}} on QB</h2>
                    <button v-ripple>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <rect x="2" y="5" width="20" height="14" rx="5" stroke="#fff" stroke-width="2" stroke-linecap="round"/> <path d="M9 10L9 14" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <path d="M11 12H7" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/> <circle cx="17" cy="10" r="1" fill="#fff"/> <circle cx="15" cy="14" r="1" fill="#fff"/> </svg>
                        play now
                    </button>

                </div>
            </div>
        </a>

       <h2 v-if="history.length > 0"> Recently played</h2>
        <div class="content-row" v-if="history.length > 0">
            <Game v-for="(g,i) in history" :key="i" :game="g" size="short" v-ripple/>
        </div>

        <GameStream></GameStream>

       <h2 v-if="saved.length > 0"> Favorites  </h2>
        <div class="content-row" v-if="saved.length > 0">
            <Saved v-for="(g,i) in saved" :key="i" :saved="g" v-ripple/>
        </div>

        <h2 v-if="tag"> Great {{tag}} titles </h2>
        <div class="content-row" v-if="feature">
            <Game v-for="(g,i) in gamesInCat(tag)" :key="i" :game="g" size="wide" v-ripple/>
        </div>


        <div class="tagpills">
            <div v-ripple v-for="(t,i) in tagcons" :key="i" @click="setActiveCon(t)" :class="{active: (activeCon === t)}">
                {{t}}
            </div>
        </div>

        <h2 v-if="feature"> More {{activeCon}} games </h2>
        <div class="content-row" v-if="feature">
            <Game v-for="(g,i) in gamesByConsole(activeCon)" :key="i" :game="g" v-ripple/>
        </div>

        <div class="tagpills">
            <div v-ripple v-for="(t,i) in tagssels" :key="i" @click="setActiveTag(t)" :class="{active: (activeTag === t)}">
                {{t}}
            </div>
        </div>
        <h2 v-if="ready"> {{activeTag}} Games</h2>
        <div class="content-row" v-if="feature">
            <Game v-for="(g,i) in games.filter((g) => {return g.tags.includes(activeTag)})" :key="i" :game="g" v-ripple/>
        </div>


        <h2> Editor's Choice </h2>
        <div class="content-row favgrid">
            <Game v-for="(g,i) in gamesInCat('fav')" :key="i" :game="g" size="short" v-ripple/>
        </div>

        <div class="seeallwrapper">
            <button @click="openGameSearch()" v-ripple> See All Games </button>
                <button @click="openToolbox" v-if="tmode" style="margin-left: 10px" v-ripple> {{tbopen ? 'Close': 'Open' }} Toolbox </button>
        </div>

        <transition name="fade">
            <div class="allGames" v-if="ready && gameSearchOpen">
                <div class="subnav">
                <div @click="closeGameSearch" v-ripple>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M16 12H8M8 12L11.5 15.5M8 12L11.5 8.5" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                </div>
                      <input ref="input" placeholder="Search Game Library" v-on:keyup="searching" />
                </div>

                <div v-for="(a,j) in 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')" :key="j">
                    <div v-if="searchGames.filter((ga) => {return ga.title.toLowerCase()[0] === a.toLowerCase()}).length > 0">
                        <h2> {{a}}</h2>
                        <div class="content-row favgrid">
                            <Game v-for="(g,i) in searchGames.filter((ga) => {return ga.title.toLowerCase()[0] === a.toLowerCase()})" :key="i" :game="g" size="short" v-ripple/>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <div v-if="tmode && tbopen">

            <h2 @click="openToolbox" class="tb" :class="{open: tbopen}"> Exec Command <svg xmlns="http://www.w3.org/2000/svg" width="20px" height="20px" viewBox="0 0 24 24" fill="none"> <path d="M9 6L15 12L9 18" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg> </h2>

            <div class="tbgrid" :class="{open: tbopen}">
                <div v-ripple class="tool" @click="toolbox('lutris')"> Lutris </div>
                <div v-ripple class="tool" @click="toolbox('yuzu')"> Yuzu </div>
                <div v-ripple class="tool" @click="toolbox('heroic')"> Heroic </div>
                <div v-ripple class="tool" @click="toolbox('steam')"> Steam </div>
                <div v-ripple class="tool" @click="toolbox('steam-w')"> Steam (wine) </div>
                <div v-ripple class="tool" @click="toolbox('wine')"> Wine </div>
                <div v-ripple class="tool" @click="toolbox('retroarch')"> Retroarch </div>
                <div v-ripple class="tool" @click="toolbox('dolphin')"> Dolphin </div>

                <div v-ripple class="tool" @click="toolbox('oversteer')">  Config Oversteer </div>

                <div v-ripple class="tool" @click="toolbox('vdON')"> Virtual Display On </div>
                <div v-ripple class="tool" @click="toolbox('vdOFF')"> Virtual Display Off</div>

                <div v-ripple class="tool" @click="toolbox('move')"> Move Windows to VD </div>
                <div v-ripple class="tool" @click="toolbox('restart')"> Reload Game </div>
            </div>
        </div>
    </div>
  </transition>
</template>

<script>
import Game from '@/components/Game.vue'
import Saved from '@/components/Saved.vue'
import GameStream from '@/components/GameStream.vue'
export default {
  name: 'Games',

  components: {
      Game,
      Saved,
      GameStream
  },

  watch: {
  },

  data: function(){
    return {
        tmode: false,
        tags: ["fighting", "sim", "indie", "current gen", "puzzle", "platformer", "sports", "beat-em-up", "racing", "open world", "shooter", "arcade", "RPG", "classic", "retro", "AAA", "inclusive"],
        tag: null,
        ready: false,
        tbopen: false,
        tagssels: [],
        tagcons: [],
        activeTag: '',
        activeCon: '',
        games: [],
        history: [],
        saved: [],
        gameSearchOpen: false,
        gameSearchQuery: '',
        searchGames: [],
        feature: null,
    }
  },

  methods: {
    openToolbox(){
        this.tbopen = !this.tbopen
    },
    toolbox(action){
        fetch(process.env.VUE_APP_SERVER + `/toolbox?action=${action}`)
    },

    ev(){
        return process.env.VUE_APP_SERVER
    },

    gamesInCat(cat){
        return (this.games.filter((g) => {
            return g.tags.includes(cat)
        }))
    },
    gamesByConsole(cons){
        return (this.games.filter((g) => {
            return g.console.includes(cons)
        }))
    },
    getConsoleIcon(game){
        return `${game.console}.png`
    },
    async getFeature() {
        //const tag = window.shuffle(this.tags)[0]
        const game = window.shuffle(this.games)

        this.feature = game[0];
        this.tag = window.shuffle(this.tags)[0]
        this.tags = window.shuffle(this.tags)
        this.tagssels = (this.tags).splice(0, 6)
        this.activeTag = this.tagssels[0]
        this.tagcons = window.shuffle(["PS2", "PS3", "NES", "Wii", "N64", "GC", "Native", "Switch"])
        this.activeCon = this.feature.console
    },
    setActiveTag(t){this.activeTag = t},
    setActiveCon(t){this.activeCon = t},
    openGameSearch(){
        this.gameSearchOpen = true
        window.history.pushState({ gameSearchOpen: this.gameSearchOpen }, '', '?search=open');
        this.$nextTick(() => {
            this.$refs.input.focus()
        })

    },

    handlePopstate(event) {
        // Handle popstate event
        if (event.state && event.state.gameSearchOpen !== undefined) {
            this.gameSearchOpen = event.state.gameSearchOpen;
        } else {
            // Default behavior when navigating back to initial state
            this.gameSearchOpen = false;
        }
    },

    closeGameSearch(){
        this.gameSearchOpen = false
        this.searchGames = this.games
        this.gameSearchQuery = ''
        window.history.replaceState({ gameSearchOpen: this.gameSearchOpen }, '', window.location.pathname);
    },

    searching(){
      this.gameSearchQuery = this.$refs.input.value
      this.debounce(this.getSearchResults(), 500)
    },
    getSearchResults(){
        this.searchGames = this.games.filter((x) => {
            return x.title.toLowerCase().includes(this.gameSearchQuery.toLowerCase())
        })
    },

    debounce(func, timeout = 300){
      let timer;
      return (...args) => {
        clearTimeout(timer);
        timer = setTimeout(() => { func.apply(this, args); }, timeout);
      };
    },

  },

  computed: {
  },

   deactivated() {
        window.removeEventListener('popstate', this.handlePopstate);
   },
  mounted: async function(){
    let request = await fetch(process.env.VUE_APP_SERVER + '/games')
    let data = await request.json()
    this.games = data.games
    this.saved = data.saved
    this.history = data.history
    this.searchGames = this.games
    this.ready = true
    this.getFeature()
    window.addEventListener('popstate', this.handlePopstate);
  }
}

</script>

<style lang="scss">

.games.page{
    width: 100vw;
    overflow: hidden;
    position: relative;
    h2{
        padding-left: 20px;
        margin-bottom: 20px;
        width: 70%;
        text-transform: capitalize;
    }
    .feature{
        position: relative;
        margin-bottom: -24px;
        .cover{
            top: 0;
            left: -5vw;
            width: 110vw;
            position: relative;
            min-height: 30vh;
            z-index: 0;
            height: 30vh;
            object-fit: cover;
            border-bottom-left-radius: 15px;
            border-bottom-right-radius: 15px;
            overflow: hidden;
            img{
                object-fit: cover;
                width: 100%;
                min-height: 40vh;
                height: auto;
                transform: translateY(-10%);
            }
            &::before{
                pointer-events: none;
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                z-index: 1;
                background: linear-gradient(0deg, rgba(#141414,1) 0%, rgba(#141414,0.84) 30%, rgba(#141414, 0) 100%);
            }
        }
        .content{
            color: white;
            top: 0;
            position: absolute;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            background-color: rgba(0,0,0,0.15);
            backdrop-filter: blur(2px);
            padding: 0 20px;
            button{
                border: 1px solid #0984e3;
                color: white;
                background-color: rgba(#0984e3, 0.45);
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 120px;
                text-transform: uppercase;
                font-size: 9px;
                letter-spacing: 1px;
                svg{
                    width: 16px;
                    padding: 4px 8px;
                }
            }
            > div{
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                font-size: 9px;
                position: absolute;
                top: 20px;
                right: 40px;
                svg{
                    padding: 10px

                }
            }
            h1{
                position: absolute;
                top: 20px;
                left: 20px;

                z-index: 4;
                margin: 0;
                padding: 0;

            }
            h2{
                margin: 0;
                text-transform: uppercase;
                position: relative;
                min-height: 20px;
                padding: 0;
                padding-top: 40px;
                z-index: 9;
                font-size: 12px;
                z-index: 12;
                width: 75%;
                text-align: left;
                margin: 30px 0;
            }
        }
        .console{
            width: 50px;
            height: 50px;
            opacity: 0.5;
            position: absolute;
            z-index: 4;
            bottom: 15px;
            right: 15px;
            background-color: white;
            border-radius: 8px;
            img{
                position: relative;
                width: 60%;
                height: auto;
                padding: 20%;
            }
        }
    }

.content-row{
    padding-bottom: 20px;
}

.favgrid{
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    grid-template-columns: 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px 200px;
    padding: 0 20px;
    display: grid;
    grid-gap: 10px;
    overflow: scroll;
    margin-bottom: 20px;
    align-items: flex-start;
    align-content: center;
}

.tb{
    display:flex;
    align-items: center;
    padding-top: 12px;
    justify-content: center;
    text-align: center;
    min-width: 100%;
    max-width: 100%;


    svg{
        margin-left: 12px;
        transition-duration: 0.3s;
    }
    &.open{
        svg{
            transform: rotate(90deg);
            transition-duration: 0.3s;
        }
    }
}
.tbgrid{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    height: 0px;
    opacity: 0;
    transition-duration: 0.3s;
    &.open{
        height: auto;
        opacity: 1;
        transition-duration: 0.3s;
    }
    > div{
        border: 1px solid white;
        padding: 2px 1px;
        text-transform: uppercase;
        width: 30%;
        font-size: 9px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 25px;
        color: white;
        margin-left: 2%;
        margin-bottom: 2%;
        text-align: center;
    }
}
.tagpills{
    display: flex;
    flex-direction: row;
    width: calc(100% - 40px);
    padding: 0 20px;
    overflow: scroll;
    margin-bottom: 5px;
    margin-top: 20px;
    >div{
        margin-right: 5px;
        white-space: nowrap;
        font-size: 11px;
        border: 1px solid white;
        padding: 2px 5px;
        border-radius: 4px;
        transition-duration: 0.3s;
        &.active{
            background: white;
            color: black;
            transition-duration: 0.3s;
        }
    }
}
.allGames{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow-y: scroll;
    z-index: 9999;
    background-color: black;
    .subnav{
        padding: 20px;
        display:flex;
        position: fixed;
        top: 0;
        left: 0;
        flex-direction: row;
        z-index: 9;
        width: 100%;
        background-color: rgba(black, 0.75);
        backdrop-filter: blur(10px); 
        align-items: center;
    }
    svg{
        padding-right: 10px;
    }
    input{
        flex-grow: 1;
        background-color: transparent;
        border: none;
        max-width: 90%;
        border-bottom: 1px solid white;
        outline: none;
        color: white;
    }
}
.seeallwrapper{
    display: flex;
    align-items:center;
    justify-content:center;
    padding-top: 20px;
    padding-bottom: 20px;

    button{
        background-color: transparent;
        text-align: center;
        border: 1px solid white;
        color: white;
        padding: 10px;
        border-radius: 6px;
    }
}

}

.gs{
    padding-top: 24px;
}
</style>
