<template>
    <transition name="fade">
        <div>
            <div class="vip" v-if="vipMode" @click="endVirtualInput">
                <div class="bg" > </div>
            </div>

          <div v-if="streaming.active" class="streamnow" :style="{'background-image': `url(${streaming.game.coverArt})`}">

            <router-link :to="'/games'" class="jump">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"> <path d="M8 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H3.6C3.26863 3 3 3.26863 3 3.6V16" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M10 6L18 6" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M6 6H7" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M3.5 20.5L12 12M12 12V16M12 12H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
            </router-link>

              <div class="style" @click="() => {streamDetailsOpen = !streamDetailsOpen}">
                  <img :src="streaming.game.coverArt" class="cover">
                  <div class="gamewide" >
                      <h2> {{streaming.game.title}} </h2>
                      <p> STREAMING ON {{streaming.client}}</p>
                  </div>
              </div>

              <div class="action" v-if="streamDetailsOpen">
                  <button v-ripple @click="startVirtualInput" class="point">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M4 10V14C4 18.4183 7.58172 22 12 22C16.4183 22 20 18.4183 20 14V9C20 5.13401 16.866 2 13 2H12C7.58172 2 4 5.58172 4 10Z" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/> <path d="M12 2V8.4C12 8.73137 12.2686 9 12.6 9H20" stroke="#fff" stroke-width="1.5" stroke-linecap="round"/> </svg>
                      Virtual Input
                  </button>

                  <button @click="stopStream" v-ripple class="end">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" fill="none"> <path d="M22 18V6C22 4.34315 20.6569 3 19 3H17C15.3431 3 14 4.34315 14 6V18C14 19.6569 15.3431 21 17 21H19C20.6569 21 22 19.6569 22 18Z" stroke="#fff" stroke-width="1.5"/> <path d="M8 3H6C3.79086 3 2 4.79086 2 7V17C2 19.2091 3.79086 21 6 21H8" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> <path d="M14 12H6M6 12L9 9M6 12L9 15" stroke="#fff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/> </svg>
                      End Stream
                  </button>
              </div>
          </div>



        </div>
    </transition>
  </template>

  <script>
  export default {
    name: 'GamesStream',

    components: {
    },

    watch: {
    },

    data: function(){
      return {
        vipMode: false,
        streamDetailsOpen: false,
        streaming: {active: false, game: null, client: null}
      }
    },

    methods: {
        stopStream(){
            fetch(process.env.VUE_APP_SERVER + `/stopGameStream?did=${window.did}`)
        },
        startVirtualInput(){
            fetch(`${process.env.VUE_APP_SERVER}/startVirtualInput`)
            this.vipMode = true
            setTimeout(() => {
                window.open('http://wr2.studio14a.com:5566/',"_blank")
            }, 500)
            navigator.vibrate(50)

        },
        endVirtualInput(){
            fetch(`${process.env.VUE_APP_SERVER}/endVirtualInput`)
            this.vipMode = false
            navigator.vibrate(50)
        },

    },

    computed: {
    },

    mounted: async function(){
        fetch(process.env.VUE_APP_SERVER +  '/gameStream').then(response => response.json()).then((data) => { this.streaming = data })

        this.$root.$on('gameStream', (x) => {
            this.streaming = x
        })
    }
  }

  </script>

  <style lang="scss">



  .streamnow{
    opacity: 0.75;
     position: relative;
      margin-top: 20px;
      margin: 20px;
      border-radius: 8px;
      background-size: cover;
      background-position: center;
      overflow: hidden;
      .cover{
          height: 75px;
          position: relative;
          margin: 10px;
          box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.3);
          border-radius: 5px;
          img{
              height: 100%;
              width: auto;
          }
      }

      .style, .action{
          background-color: rgba(black, 0.55);
          backdrop-filter: blur(7.5px);
      }
      .style{
          display: flex;
          flex-direction: row;
      }

      .gamewide {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 100%;
      }

      h2, p, .action{
          margin: 0;
          padding: 0;
          color: white;
      }

      .action{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          padding: 20px 10px;
          width: 70%;
          padding-left: 25%;
          padding-right: 5%;
      }
      button{
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border: 1px solid white;
          background-color: transparent;
          border-radius: 5px;
          color: white;
          width: 48%;
          font-size: 9px;
          text-transform: uppercase;
          svg{
              margin-right: 10px;
              width: 15px;
          }
          &.point{
              border-color: #0984e3;
              background-color: rgba(#0984e3, 0.5);
          }
          &.end{
              border-color: #d63031;
              background-color: rgba(#d63031, 0.5);
          }
      }
      h2{
          padding-left: 10px!important;
          padding-right: 10px!important;
          font-size: 18px;
      }
      p{
          padding-left: 10px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-top: 10px;
          font-size: 9px;

      }

      .game-wide{
          position: relative;
          overflow: hidden;
          display: flex;
          background-size: cover;
          background-position: center;
          width: 100%;
          min-height: 325px;
          border-radius: 8px;
      }
  }

  .vip{
    position: fixed;
    pointer-events: all;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.6);
    backdrop-filter: blur(20px);
    z-index: 99999999999999;
  };
  .jump{
    top: 0;
    right: 0;
    position: absolute;
    z-index: 9;
    width: 15px;
    height: 15px;
    padding: 15px;
  }
  </style>


