<template>
    <router-link :to="'/' + (saved.type === 'od' ? saved.contentType : saved.type) + '/' + saved.id" class="saved" :style="{'background-image': `url(${saved.imgs[0]}` }" v-ripple :class='size'>
      <div>
          <p v-if="saved.type === 'game'"> {{saved.name}} </p>
      </div>
    </router-link>
</template>

<script>

export default {
  name: 'saved',
  props: {
    saved: Object,
    size: {
        type: String,
        default: function(){ return ''}
    }
  },

  computed: { },

  data: function(){
      return {}
  },

  methods: {

  },

  mounted: function(){
  }
}
</script>

<style scoped lang="scss">
.saved{
    min-width: 150px;
    position: relative;
    height: 250px;
    overflow: hidden;
    border-radius: 8px;
    background-size: cover;
    background-position: center;
    margin-right: 10px;
    color: white;
    display: block;
    &.wide{
        min-width: 350px;
        height: 200px;
        background-position: top center;
        div{
            width: 33%;
            left: unset;
            margin: 5px;
            height: calc(100% - 10px);
            border-radius: 8px;
            right: 0;
            display: flex;
            height: calc(100% - 10px);
            background: rgba(black, 0.2);
            backdrop-filter: blur(4px);
            p{
                align-self: center;
                width: 100%;
                padding: 10px;
                text-align: right;
            }
            img{
                padding: 5px;
                border-radius: 4px;
                width: 30px;
                height: 30px;
                position: absolute;
                bottom: 0;
                right: 0;
                z-index: 99;
            }
        }
    }
    &.short{
        height: 150px
    }

    div{
        width: calc(100% - 10px);
        margin: 5px;
        border-radius: 8px;
        left: 0;
        bottom: 0;
        position: absolute;
        background: rgb(207 205 205 / 7%);
        backdrop-filter: blur(10px);
        p{
            margin: 0;
            font-weight: bold;
            font-size: 9px;
            padding: 15px;
        }
    }
}
</style>
